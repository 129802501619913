import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { ClientService } from '../../../api/ClientService';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

function CreateTask({accessToken}) {

    const { register, handleSubmit } = useForm();
    const [show, setShow] = useState(false);
    const clientService = new ClientService(accessToken);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onSubmit = (data, e) => {
        // TODO: NEED TO CLEAN UP, WE ARE NOT CREATING CLIENT ATM
        clientService.createClient(data).then(response => {
            // props.clientCreated();
            e.target.reset();
        });
    };

    return (
        <div>
            <Button variant="contained" color='primary' onClick={handleShow} style={{margin: '5px' }}>
                Add New Client
            </Button>
            <Dialog open={show} onClose={handleClose}>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mrgnbtm" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label htmlFor="clientPrimaryName" style={{fontWeight: 'bold'}}>Client's Primary Name </label>
                                <input {...register("clientPrimaryName")} placeholder="Client's Primary Name" className="form-control" name="clientPrimaryName" id="clientPrimaryName" />
                            </div>
                        </div>
                        {/* <div className="row mrgnbtm" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label htmlFor="exampleInputPassword1" style={{fontWeight: 'bold'}}>Client's Copper ID </label>
                                <input {...register("clientCopperId")} placeholder="Client's Copper ID" className="form-control" name="clientCopperId" id="clientCopperId" />
                            </div>
                        </div> */}
                        <div className="row mrgnbtm" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label htmlFor="entityForClient" style={{fontWeight: 'bold'}}>Our Entity for Client </label>
                                <select className="form-control" {...register("entityForClient")}>
                                    <option>Please select...</option>
                                    <option>SDM Inc.</option>
                                    <option>SDM Financial</option>
                                    <option>Greenline</option>
                                    <option>GLE</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mrgnbtm" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label htmlFor="fee" style={{fontWeight: 'bold'}}>Client's Commission Fee </label>
                                <input {...register("fee")} placeholder="Fee" className="form-control" name="fee" id="fee" />
                            </div>
                        </div>
                        <div className="row mrgnbtm" style={{margin: '5px'}}>
                            <div className="form-group col-md-6">
                                <label htmlFor="coryCommission" style={{fontWeight: 'bold'}}>Cory Commission </label>
                                <input {...register("coryCommission")} placeholder="0 (optional)" className="form-control" name="coryCommission" id="coryCommission" />
                            </div>
                        </div>
                        <input type="submit" className="btn btn-danger mrgnbtm" />
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CreateTask;