import React, { useState, useEffect } from 'react';
import Tasks from './Tasks';
import CreateTask from './CreateTask';
import { ClientService } from '../../../api/ClientService';
import CircularProgress from '@mui/material/CircularProgress';
import usePagination from "../../../hooks/usePagination";

function ClientMapping({ accessToken }) {
    const [tasks, setTasks] = useState([])
    // console.log('Home() tasks', tasks)
    const [numberOfClients, setNumberOfClients] = useState(0)
    const [isTaskEdited, setTaskEdited] = useState(false)
    const [isWalletAdded, setWalletAdded] = useState(false)
    const [deletedWallet, setDeletedWallet] = useState(null)
    const [isClientnameAdded, setClientnameAdded] = useState(false)
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(0);
    const [balanceUpdate, setBalanceUpdate] = useState(0);

    const [searchString, setSearchString] = useState("");
    const [noResultsFound, setNoResultsFound] = useState(false);

    const paginationProps = usePagination();
    const clientService = new ClientService(accessToken);


    useEffect(() => {
        paginationProps.setPageIndex(0);
    }, [searchString]);
    const fetchSearchData = async () => {
        setLoading(true)
    
        try {
            let url;
            if (searchString.trim() !== "") {
                // Construct URL for filtered data with combinedclientdata route
                url = `${process.env.REACT_APP_API_URL}/combinedclientdata?size=${paginationProps.pageSize}&page=${paginationProps.pageIndex}&searchQuery=${encodeURIComponent(searchString)}&settled_filter=ALL`;
            } else {
                // Construct URL for all clients with combinedclientdata route
                url = `${process.env.REACT_APP_API_URL}/combinedclientdata?size=${paginationProps.pageSize}&page=${paginationProps.pageIndex}&settled_filter=ALL`;
            }
            
            // Make an HTTP request to your backend endpoint for filtering
            const response = await fetch(url, {
                method: "GET",
                headers: {
                // "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                },
            });
        
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
        
            const data = await response.json();
            
            setTasks(data.data);
            paginationProps.setTotalItemCount(data.totalItems);
            setNoResultsFound(data.data.length === 0);
        } catch (error) {
            console.error("Error fetching data:", error);
            setNoResultsFound(false); // Reset in case of an error
        }
        finally {
            setLoading(false)
        }
    };
    useEffect(() => {
        if (searchString.trim().length === 0) {
            fetchSearchData();
        }
    }, [searchString, paginationProps.pageSize, paginationProps.pageIndex]);
    // useEffect(() => {
    //     clientService.getAllClients().then(tasks => {
    //         console.log('useEffect() tasks', tasks)
    //         setTasks(tasks)
    //         setLoading(false)
    //     }).catch(err => {
    //         console.log(err)
    //         setLoading(false)

    //     });
    // }, [
    //     numberOfClients,
    //     isTaskEdited,
    //     isWalletAdded,
    //     deletedWallet,
    //     isClientnameAdded,
    //     update
    // ]);

    useEffect(() => {
        // console.log(update + "!!!!!!!")
    },[update]);

    function delTask(clientId) {
        clientService.deleteClient(clientId).then(response => {
            console.log(response)
            setNumberOfClients(numberOfClients - 1)
        });
    }
    
    // function clientCreated() {
    //     setNumberOfClients(numberOfClients + 1)
    // }
    
    function taskEdited(res) {
        setTaskEdited(res)
    }

    function walletAdded(res) {
        setWalletAdded(res)
    }

    function clientnameAdded(res) {
        setClientnameAdded(res)
    }

    if(loading) {
        return <CircularProgress />
    }

    return (
        <div>
            <CreateTask accessToken={accessToken}></CreateTask>
            <form
                onSubmit={(e) => {
                e.preventDefault();
                fetchSearchData();
                }}
            >
                <input
                type="text"
                placeholder="Search clients"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                style={{ margin: '1em', height: '20px', width: '200px', fontSize: '18px' }}
                />
                <button type="submit">Search</button>
            </form>
            {noResultsFound && <p>No results found.</p>}
            <Tasks 
                tasks={tasks}
                deleteClient={delTask}
                taskEdited={taskEdited}
                walletAdded={walletAdded}
                clientnameAdded={clientnameAdded}
                update={update}
                setUpdate={setUpdate}
                accessToken={accessToken}
                setDeletedWallet={setDeletedWallet}
                balanceUpdate={balanceUpdate}
                setBalanceUpdate={setBalanceUpdate}
                paginationProps={paginationProps}
                // clientFilter={clientFilter}
                // setClientFilter={setClientFilter}
            />
        </div>
    );
}

export default ClientMapping;